import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Estado para controlar la visibilidad de la contraseña
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      const token = data.token;

      localStorage.setItem('token', token);

      console.log('Login successful', data);

      navigate('/dashboard');
    } catch (error) {
      console.error('Error during login:', error);
      setError('Nombre de usuario o contraseña incorrectos');
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8 font-sans">
      <div className="flex-grow flex items-center justify-center w-full">
        <div className="w-full max-w-md bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
          <div className="mb-6 text-center">
            <img
              alt="Agromaderas logo"
              className="h-16 w-auto sm:h-20 md:h-24 mx-auto"
              src="/agromaderas_logo.png"
            />
            <h2 className="text-2xl font-bold mt-4 text-gray-900">Iniciar sesión</h2>
          </div>
          {error && (
            <div className="text-red-500 text-sm mb-4">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="text-left">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                Nombre de usuario
              </label>
              <input
                id="username"
                type="text"
                placeholder="Ingrese su nombre de usuario"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                           focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
              />
            </div>
            <div className="text-left">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Contraseña
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Ingrese su contraseña"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 pr-10 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                             focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)} // Cambia el estado para mostrar/ocultar la contraseña
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  {showPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 3C5.5 3 2 6.5 2 10s3.5 7 8 7 8-3.5 8-7-3.5-7-8-7zm0 2c1.36 0 2.615.514 3.536 1.435a5.008 5.008 0 010 7.13A5.008 5.008 0 0110 15c-1.36 0-2.615-.514-3.536-1.435a5.008 5.008 0 010-7.13A5.008 5.008 0 0110 5zm0 2c-.535 0-1.04.21-1.414.586A2 2 0 0010 11a2 2 0 001.414-.586A2 2 0 0010 7z" />
                      <path d="M4.293 4.293a1 1 0 011.414 0l10 10a1 1 0 01-1.414 1.414l-10-10a1 1 0 010-1.414z" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 3C5.5 3 2 6.5 2 10s3.5 7 8 7 8-3.5 8-7-3.5-7-8-7zm0 2c1.36 0 2.615.514 3.536 1.435a5.008 5.008 0 010 7.13A5.008 5.008 0 0110 15c-1.36 0-2.615-.514-3.536-1.435a5.008 5.008 0 010-7.13A5.008 5.008 0 0110 5zm0 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
              >
                Iniciar sesión
              </button>
            </div>
          </form>
          <div className="mt-6 text-center">
          <a href="/reset-password" className="text-sm text-gray-600 hover:text-gray-800 transition-colors duration-200">
            ¿Olvidó su contraseña?
          </a>
          </div>
        </div>
      </div>
      <div className="w-full text-center py-4">
        <p className="text-xs text-gray-500">Powered by <b>ZALBEX Technologies</b></p>
      </div>
    </div>
  );
}
