import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { FaEdit, FaTrash, FaSearch, FaEllipsisV, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { createPortal } from 'react-dom';
import { Menu } from '@headlessui/react'
import CrearCliente from './CrearCliente.tsx';
import EditarCliente from './EditarCliente.tsx'
import ClienteDetails from './ClienteDetails.tsx';
import ModalConfirmacion from '../ModalConfirmacion.tsx';
import { Loader2 } from "lucide-react";
import { ToastContainer, toast } from 'react-toastify';

export default function Clientes() {
  const navigate = useNavigate();
  const [clientes, setClientes] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('full_name');
  const [showCrearCliente, setShowCrearCliente] =   useState(false);
  const [showEditarCliente, setShowEditarCliente] = useState(false);
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [clienteAEliminar, setClienteAEliminar] = useState(null);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [editingCliente, setEditingCliente] = useState(null);
  const clientesPorPagina = 10;
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);

  const fetchClientes = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/clients/', {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Error al obtener los clientes');
      const data = await response.json();
      const clientesFormateados = data.map((cliente) => ({
        id: cliente.django_user.id,
        full_name: cliente.full_name,
        email: cliente.django_user.email,
        ...cliente
      }));
      setClientes(clientesFormateados);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClientes();
  }, []);

  const handleClienteCreado = () => {
    fetchClientes();
    setShowCrearCliente(false);
  };

  const handleClienteEditado = () => {
    fetchClientes(); // Actualiza la lista de envíos
    setShowEditarCliente(false); // Cierra el modal de crear envío
  };

  const handleCrearCliente = () => {
    setEditingCliente(null);
    setShowCrearCliente(true);
  };

  const handleEditCliente = (cliente) => {
    setEditingCliente(cliente);
    setShowEditarCliente(true);
  };

  const cambiarPagina = (nuevaPagina) => {
    setPaginaActual(nuevaPagina);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchFieldChange = (e) => {
    setSearchField(e.target.value);
  };

  const clientesFiltrados = clientes.filter((cliente) => 
    cliente[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indiceUltimoCliente = paginaActual * clientesPorPagina;
  const indicePrimerCliente = indiceUltimoCliente - clientesPorPagina;
  const clientesActuales = clientesFiltrados.slice(indicePrimerCliente, indiceUltimoCliente);

  const totalPaginas = Math.ceil(clientesFiltrados.length / clientesPorPagina);

  const confirmarEliminacion = async () => {
    if (clienteAEliminar) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/clients/?user_id=${clienteAEliminar}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.description_es || 'Eliminacion de cliente fallida';
          toast.error(errorMessage);
        }
        setClientes(clientes.filter((cliente) => cliente.id !== clienteAEliminar));
        setShowModalConfirmacion(false);
        setClienteAEliminar(null);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleDelete = (clienteId) => {
    setClienteAEliminar(clienteId);
    setShowModalConfirmacion(true);
  };

  const cancelarEliminacion = () => {
    setShowModalConfirmacion(false);
    setClienteAEliminar(null);
  };

  const handleViewDetails = (cliente) => {
    setSelectedCliente(cliente);
  };

  const handleMenuOpen = (event, envioId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOpenMenuId(envioId);
  };

  const handleMenuClose = useCallback(() => {
    setOpenMenuId(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleMenuClose]);

  function Spinner() {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    )
  }

  return (
    <div className="p-4 md:p-6 max-w-6xl mx-auto relative bg-gray-100 min-h-screen">
      {showCrearCliente && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CrearCliente
            onClose={() => setShowCrearCliente(false)}
            onClienteCreado={handleClienteCreado}
            editingCliente={editingCliente}
          />
        </div>
      )}
      {showEditarCliente && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <EditarCliente
            onClose={() => setShowEditarCliente(false)}
            onClienteEditado={handleClienteEditado}
            editingClient={editingCliente}
          />
        </div>
      )}
      {showModalConfirmacion && (
        <ModalConfirmacion
          mensaje="¿Estás seguro de que quieres eliminar este cliente?"
          onConfirm={confirmarEliminacion}
          onCancel={cancelarEliminacion}
        />
      )}
      {selectedCliente && (
        <ClienteDetails
          client={selectedCliente}
          onClose={() => setSelectedCliente(null)}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
                <h1 className="text-3xl font-bold">Clientes</h1>
                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <div className="relative w-full md:w-64">
                    <input
                      type="text"
                      placeholder="Buscar cliente"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="border rounded-md py-2 px-3 pr-10 w-full"
                    />
                    <svg
                      className="absolute right-3 top-2.5 h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <button
                    className="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-200 w-full md:w-auto"
                    onClick={handleCrearCliente}
                  >
                    + Nuevo cliente
                  </button>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                <div className="bg-white rounded-lg shadow overflow-x-auto">
                  <ToastContainer />
                  <table className="w-full">
                    <thead>
                      <tr className="bg-gray-50 text-left">
                        <th className="py-3 px-4 font-semibold text-sm text-gray-600">Nombre completo</th>
                        <th className="py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Email</th>
                        <th className="py-3 px-4 font-semibold text-sm text-gray-600">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientesActuales.map((cliente) => (
                        <tr key={cliente.id} className="border-b hover:bg-gray-50">
                          <td className="py-3 px-4 text-sm">{cliente.full_name}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell">{cliente.email}</td>
                          <td className="py-3 px-4 md:px-6">
                            <div className="hidden md:flex space-x-2">
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#0984e3' }} 
                                onClick={() => handleViewDetails(cliente)}
                              >
                                <FaSearch />
                              </button>
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#f1c40f' }}  
                                onClick={() => handleEditCliente(cliente)}
                              >
                                <FaEdit />
                              </button>
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#e74c3c' }}  
                                onClick={() => handleDelete(cliente.id)}
                              >
                                <FaTrash />
                              </button>
                            </div>
                            <div className="md:hidden">
                            <button
                                  className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                  onClick={(e) => handleMenuOpen(e, cliente.id)}
                                >
                                  <FaEllipsisV className="text-xl" />
                                </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </>
              )}
              <div className="mt-4 flex justify-center items-center space-x-2">
                {paginaActual > 1 && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual - 1)}
                  >
                    Anterior
                  </button>
                )}
                <div className="flex space-x-2">
                  {[...Array(totalPaginas)].map((_, index) => (
                    <button
                      key={index + 1}
                      className={`px-3 py-1 rounded-md ${
                        paginaActual === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-gray-700 border hover:bg-gray-100'
                      }`}
                      onClick={() => cambiarPagina(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                {paginaActual < totalPaginas && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual + 1)}
                  >
                    Siguiente
                  </button>
                )}
              </div>
            </>
          }
        />
      </Routes>
      {/* Menú desplegable para móviles */}
      {openMenuId && createPortal(
        <div
          ref={menuRef}
          style={{
            position: 'absolute',
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
            zIndex: 1000,
          }}
        >
          <div className="bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleViewDetails(clientesActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaSearch className="mr-2" /> Ver detalles
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleEditCliente(clientesActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaEdit className="mr-2" /> Editar
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleDelete(openMenuId);
                  handleMenuClose();
                }}
              >
                <FaTrash className="mr-2" /> Eliminar
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
}