'use client'

import React from 'react'
import { Menu, LogOut } from 'lucide-react'
import { useNavigate } from 'react-router-dom';

function Sidebar({ onSelect, selected, isOpen, onToggle }: { 
  onSelect: (option: string) => void, 
  selected: string,
  isOpen: boolean,
  onToggle: () => void
}) {
  const navigate = useNavigate();

  const menuItems = [
    { name: 'Estadisticas' },
    { name: 'Usuarios' },
    { name: 'Clientes' },
    { name: 'Ordenes' },
    { name: 'Producciones' },
    { name: 'Envios' },
  ]

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/logout/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      })
      if (response.ok) {
        console.log('Logged out successfully')
        // Redirect to the login page
        navigate('/login');
      } else {
        console.error('Logout failed')
      }
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  return (
    <>
      <button 
        className="lg:hidden fixed top-4 left-4 z-20 p-2 bg-white rounded-md shadow-md"
        onClick={onToggle}
        aria-label="Toggle menu"
      >
        <Menu size={24} />
      </button>
      <div className={`
        bg-white w-64 h-screen p-4 flex flex-col shadow fixed left-0 top-0 z-10
        transition-transform duration-300 ease-in-out
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        lg:translate-x-0 lg:static
      `}>
        <div className="mb-8">
          <img
            alt="Agromaderas logo"
            className="h-16 w-auto sm:h-20 md:h-24 mx-auto"
            src="/agromaderas_logo.png"
          />
        </div>
        {menuItems.map((item) => (
          <button
            key={item.name}
            className={`flex items-center p-2 rounded-md mb-2 text-left w-full ${
              selected === item.name
                ? 'bg-[#3686FF] text-white'
                : 'text-gray-700 hover:bg-gray-100'
            } transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#3686FF] focus:ring-opacity-50`}
            onClick={() => {
              onSelect(item.name)
              if (window.innerWidth < 1024) onToggle()
            }}
          >
            <span className="text-sm font-medium">{item.name}</span>
          </button>
        ))}
        <button
          className="mt-auto flex items-center p-2 rounded-md mb-2 text-left w-full text-gray-700 hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#3686FF] focus:ring-opacity-50"
          onClick={handleLogout}
        >
          <LogOut className="mr-2 h-4 w-4" />
          <span className="text-sm font-medium">Cerrar sesión</span>
        </button>
      </div>
    </>
  )
}

export default Sidebar