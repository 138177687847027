import React, { useState, useEffect } from 'react';

interface UserDetailsProps {
  user: {
    django_user: {
      first_name: string;
      last_name: string;
      email: string;
      username: string;
    };
    full_name: string;
    role: {
      name: string;
    };
    city: {
      id: number;
      state: {
        id: number;
        country: {
          id: number;
          name: string;
        };
        name: string;
      };
      name: string;
    };
    created_at: string;
    updated_at: string;
    id_number: number;
    birth_date: string;
    gender: string;
    address: string;
    phone: string;
    user_type: string;
  };
  onClose: () => void;
}

const roleTranslation: { [key: string]: string } = {
  admin: 'Administrador',
    secretary: 'Secretario',
    operator: 'Operador'
};

const UserDetails: React.FC<UserDetailsProps> = ({ user, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300); // Delay closing to allow animation to complete
  };

  const translateGender = (gender: string) => {
    return gender === 'male' ? 'Masculino' : gender === 'female' ? 'Femenino' : gender;
  };

  const translateUserType = (userType: string) => {
    return userType === 'client' ? 'Cliente' : userType === 'admin' ? 'Administrador' : userType === 'employee' ? 'Empleado' : userType;
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 py-8 text-center">
        <div className={`inline-block w-full max-w-md sm:max-w-lg md:max-w-2xl p-4 sm:p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <div className="flex justify-between items-center mb-4 sm:mb-6">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900">Detalles del Usuario</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="space-y-4 sm:space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Username</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.django_user.username}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Nombre</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.django_user.first_name}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Apellido</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.django_user.last_name}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.django_user.email}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Rol</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {roleTranslation[user.role.name] || user.role.name}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Ciudad</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.city.name}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Estado</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.city.state.name}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">País</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.city.state.country.name}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Fecha de Creación</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {new Date(user.created_at).toLocaleString()}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Fecha de Actualización</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {new Date(user.updated_at).toLocaleString()}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">DNI</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.id_number}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Fecha de Nacimiento</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {new Date(user.birth_date).toLocaleDateString()}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Género</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {translateGender(user.gender)}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Dirección</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.address}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Teléfono</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {user.phone}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Tipo de Usuario</label>
                <div className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  {translateUserType(user.user_type)}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={handleClose}
              className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;