import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Component({ editingProduccion, onClose, onProduccionEditada }) {
  const navigate = useNavigate();
  const [ordenes, setOrdenes] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    details: '',
    estimated_start_date: '',
    estimated_end_date: '',
    price: '',
    order: '',
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (editingProduccion) {
      setFormData({
        name: editingProduccion.name || '',
        details: editingProduccion.details || '',
        estimated_start_date: editingProduccion.estimated_start_date || '',
        estimated_end_date: editingProduccion.estimated_end_date || '',
        price: editingProduccion.price || '',
        order: editingProduccion.order || '',
      });
    }
  }, [editingProduccion]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchOrdenes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/orders/', {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
          },
        });

        if (response.ok) {
          const ordenesData = await response.json();
          setOrdenes(ordenesData);
        } else {
          toast.error('Error al cargar la lista de ordenes');
        }
      } catch (error) {
        toast.error('Error al intentar obtener las ordenes');
      }
    };

    fetchOrdenes();
    setIsOpen(true);
  }, []);

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cleanedFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => typeof value === 'string' && value.trim() !== '')
    );

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/productions/?id=${editingProduccion.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(cleanedFormData),
      });

      if (response.ok) {
        toast.success('Produccion actualizada con éxito');
        await sleep(1500);
        onProduccionEditada();
        handleClose();
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.description_es || 'Actualizacion de produccion fallida';
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error('Error al intentar actualizar la produccion');
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300); // Delay closing to allow animation to complete
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className={`inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <ToastContainer />
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Editar Produccion</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="order" className="block text-sm font-medium text-gray-700">
                  Orden
                </label>
                <select
                  id="order"
                  name="order"
                  value={formData.order}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                >
                  <option value="">Selecciona una orden</option>
                  {ordenes.map((order) => (
                    <option key={order.id} value={order.id}>
                      #{order.id} {order.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Nombre
                </label>
                <input
                  type="name"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="estimated_start_date" className="block text-sm font-medium text-gray-700">
                  Fecha estimada de inicio
                </label>
                <input
                  type="date"
                  id="estimated_start_date"
                  name="estimated_start_date"
                  value={formData.estimated_start_date}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="estimated_end_date" className="block text-sm font-medium text-gray-700">
                  Fecha estimada de fin
                </label>
                <input
                  type="date"
                  id="estimated_end_date"
                  name="estimated_end_date"
                  value={formData.estimated_end_date}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                  Precio
                </label>
                <input
                  type="text"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
            </div>
            <div>
                <label htmlFor="details" className="block text-sm font-medium text-gray-700">
                  Detalles de la produccion
                </label>
                <textarea
                  id="details"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out h-32"
                  rows="4"
                  required
                />
              </div>
            <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleClose}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200 ease-in-out"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
                >
                  Guardar Cambios
                </button>
                </div>
          </form>
        </div>
      </div>
    </div>
  );
}