import React, { useState, useEffect } from 'react';

interface ModalConfirmacionProps {
  mensaje: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalConfirmacion: React.FC<ModalConfirmacionProps> = ({ mensaje, onConfirm, onCancel }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleConfirm = () => {
    setIsOpen(false);
    setTimeout(onConfirm, 300);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setTimeout(onCancel, 300);
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleCancel}></div>
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className={`inline-block w-full max-w-sm sm:max-w-md md:max-w-lg p-4 sm:p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <h2 className="text-lg sm:text-xl font-semibold mb-4">{mensaje}</h2>
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
            <button
              className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-200 ease-in-out"
              onClick={handleConfirm}
            >
              Confirmar
            </button>
            <button
              className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200 ease-in-out"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmacion;