import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CrearEnvio({ onClose, onEnvioCreado }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [ordenes, setOrdenes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    estimated_delivery_date: '',
    real_delivery_date: null,
    details: '',
    order: '',
    address: '',
  });
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(''); // Campo adicional para mostrar los detalles de la orden seleccionada

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Si cambia la orden, busca la orden seleccionada y actualiza 'selectedOrderDetails'
    if (name === 'order') {
      const selectedOrder = ordenes.find((order) => order.id === parseInt(value));
      setSelectedOrderDetails(selectedOrder ? selectedOrder.details : ''); // Actualiza los detalles de la orden seleccionada
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const fetchOrdenes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/orders/', {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
          },
        });

        if (response.ok) {
          const ordenesData = await response.json();
          setOrdenes(ordenesData);
        } else {
          toast.error('Error al cargar la lista de ordenes');
        }
      } catch (error) {
        toast.error('Error al intentar obtener las ordenes');
      }
    };

    fetchOrdenes();
    setIsOpen(true);
  }, []);

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/shipment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success('Envío creado con éxito');
        await sleep(1500);
        onEnvioCreado(); // Llamamos al callback para actualizar la lista de envíos
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.description_es || 'Creación de envío fallida';
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error('Error al intentar crear el envío');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300); // Delay closing to allow animation to complete
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className={`inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <ToastContainer />
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Crear Envio</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="order" className="block text-sm font-medium text-gray-700">
                  Orden
                </label>
                <select
                  id="order"
                  name="order"
                  value={formData.order}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                >
                  <option value="">Selecciona una orden</option>
                  {ordenes.map((order) => (
                    <option key={order.id} value={order.id}>
                      #{order.id} {order.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="estimated_delivery_date" className="block text-sm font-medium text-gray-700">
                  Fecha estimada de entrega
                </label>
                <input
                  type="date"
                  id="estimated_delivery_date"
                  name="estimated_delivery_date"
                  value={formData.estimated_delivery_date}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                />
              </div>
              <div>
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  Direccion de entrega
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                />
              </div>
            </div>
            <div>
                <label htmlFor="details" className="block text-sm font-medium text-gray-700">
                  Detalles del envio
                </label>
                <textarea
                  id="details"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out h-32"
                  rows="4"
                  required
                />
              </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={handleClose}
                className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                  isSubmitting ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creando...' : 'Crear Envio'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
