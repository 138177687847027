import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { FaEdit, FaTrash, FaSearch, FaEllipsisV, FaSort, FaSortUp, FaSortDown, FaSync } from 'react-icons/fa';
import { createPortal } from 'react-dom';
import { Menu } from '@headlessui/react'
import CrearProduccion from './CrearProduccion.tsx';
import EditarProduccion from './EditarProduccion.tsx';
import ModalConfirmacion from '../ModalConfirmacion.tsx';
import ProductionDetails from './ProduccionDetails.tsx';
import UpdateStatus from './UpdateStatus.tsx';
import { Loader2 } from "lucide-react";
import { ToastContainer, toast } from 'react-toastify';

const getStatusStyle = (status) => {
  switch (status) {
    case 'pending':
      return 'bg-yellow-100 text-yellow-800';
    case 'confirm':
      return 'bg-green-100 text-green-800';
    case 'in_production':
      return 'bg-blue-100 text-blue-800';
    case 'ready_for_shipment':
      return 'bg-purple-100 text-purple-800';
    case 'ended':
      return 'bg-gray-100 text-gray-800';
    case 'canceled':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const getStatusLabel = (status) => {
  switch (status) {
    case 'pending':
      return 'Pendiente';
    case 'confirm':
      return 'Confirmado';
    case 'in_production':
      return 'En producción';
    case 'ready_for_shipment':
      return 'Listo para entrega';
    case 'ended':
      return 'Terminada';
    case 'canceled':
      return 'Cancelado';
    default:
      return status;
  }
};

export default function Producciones() {
  const navigate = useNavigate();
  const [producciones, setProducciones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginaActual, setPaginaActual] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('details');
  const [showCrearProduccion, setShowCrearProduccion] = useState(false);
  const [showEditarProduccion, setShowEditarProduccion] = useState(false);
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [produccionAEliminar, setProduccionAEliminar] = useState(null);
  const [selectedProduccion, setSelectedProduccion] = useState(null);
  const [editingProduccion, setEditingProduccion] = useState(null);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [produccionToUpdate, setProduccionToUpdate] = useState(null);
  const [sortField, setSortField] = useState('name'); // Campo por el cual ordenar
  const [sortOrder, setSortOrder] = useState('asc'); // Orden ascendente o descendente
  const produccionesPorPagina = 10;
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);

  const fetchProducciones = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/productions/', {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Error al obtener las producciones');
      const data = await response.json();
      const produccionesFormateadas = data.map((produccion) => ({
        id: produccion.id,
        name: produccion.name,
        status: produccion.status,
        created_at: produccion.created_at,
        estimated_start_date: produccion.estimated_start_date,
        estimated_end_date: produccion.estimated_end_date,
        details: produccion.details,
        price: produccion.price,
        order: produccion.order,
        ...produccion
      }));
      setProducciones(produccionesFormateadas);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducciones();
  }, []);

  const handleProduccionCreada = () => {
    fetchProducciones(); // Actualiza la lista de envíos
    setShowCrearProduccion(false); // Cierra el modal de crear envío
  };

  const handleProduccionEditada = () => {
    fetchProducciones(); // Actualiza la lista de envíos
    setShowEditarProduccion(false); // Cierra el modal de crear envío
  };

  const handleEstadoActualizado = () => {
    fetchProducciones(); // Actualiza la lista de envíos
    setShowUpdateStatus(false); // Cierra el modal de crear envío
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Cambia el orden si es el mismo campo
    } else {
      setSortField(field); // Cambia el campo de ordenamiento
      setSortOrder('asc'); // Por defecto, orden ascendente
    }
  };

  const ordenar = (ordenes) => {
    return ordenes.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleCrearProduccion = () => {
    setEditingProduccion(null);
    setShowCrearProduccion(true);
  };

  const handleEditProduccion = (produccion) => {
    setEditingProduccion(produccion);
    setShowEditarProduccion(true);
  };

  const cambiarPagina = (nuevaPagina) => {
    setPaginaActual(nuevaPagina);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchFieldChange = (e) => {
    setSearchField(e.target.value);
  };

  const produccionesFiltradas = ordenar(
    producciones.filter((orden) =>
      orden[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indiceUltimoProduccion = paginaActual * produccionesPorPagina;
  const indicePrimerProduccion = indiceUltimoProduccion - produccionesPorPagina;
  const produccionesActuales = produccionesFiltradas.slice(indicePrimerProduccion, indiceUltimoProduccion);

  const totalPaginas = Math.ceil(produccionesFiltradas.length / produccionesPorPagina);

  const confirmarEliminacion = async () => {
    if (produccionAEliminar) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/productions/?id=${produccionAEliminar}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.description_es || 'Eliminacion de produccion fallida';
          toast.error(errorMessage);
        } else {
          setProducciones(producciones.filter((produccion) => produccion.id !== produccionAEliminar));
          toast.success('Produccion eliminada con éxito');
        }
        setShowModalConfirmacion(false);
        setProduccionAEliminar(null);
      } catch (error) {
        console.error('Error:', error);
        toast.error('Error al intentar eliminar la orden');
      }
    }
  };

  const handleDelete = (productionId) => {
    setProduccionAEliminar(productionId);
    setShowModalConfirmacion(true);
  };

  const cancelarEliminacion = () => {
    setShowModalConfirmacion(false);
    setProduccionAEliminar(null);
  };

  const handleViewDetails = (produccion) => {
    setSelectedProduccion(produccion);
  };

  const handleUpdateStatus = (produccion) => {
    console.log('Abriendo UpdateStatus para:', produccion); // Agrega esta línea
    setProduccionToUpdate(produccion);
    setShowUpdateStatus(true);
  };

  const handleMenuOpen = (event, envioId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOpenMenuId(envioId);
  };

  const handleMenuClose = useCallback(() => {
    setOpenMenuId(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleMenuClose]);

  function Spinner() {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    )
  }

  return (
    <div className="p-4 md:p-6 max-w-6xl mx-auto relative bg-gray-100 min-h-screen">
      {showCrearProduccion && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CrearProduccion
            onClose={() => setShowCrearProduccion(false)}
            onProduccionCreada={handleProduccionCreada}
            editingProduccion={editingProduccion}
          />
        </div>
      )}
      {showEditarProduccion && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <EditarProduccion
            onClose={() => setShowEditarProduccion(false)}
            onProduccionEditada={handleProduccionEditada}
            editingProduccion={editingProduccion}
          />
        </div>
      )}
      {showModalConfirmacion && (
        <ModalConfirmacion
          mensaje="¿Estás seguro de que quieres eliminar esta produccion?"
          onConfirm={confirmarEliminacion}
          onCancel={cancelarEliminacion}
        />
      )}
      {selectedProduccion && (
        <ProductionDetails
          produccion={selectedProduccion}
          onClose={() => setSelectedProduccion(null)}
        />
      )}
      {showUpdateStatus && (
        <UpdateStatus
          editingProduccion={produccionToUpdate}
          onEstadoActualizado={handleEstadoActualizado}
          onClose={() => setShowUpdateStatus(false)}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
                <h1 className="text-3xl font-bold">Producciones</h1>
                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <div className="relative w-full md:w-64">
                    <input
                      type="text"
                      placeholder="Buscar produccion"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="border rounded-md py-2 px-3 pr-10 w-full"
                    />
                    <svg
                      className="absolute right-3 top-2.5 h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <button
                    className="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-200 w-full md:w-auto"
                    onClick={handleCrearProduccion}
                  >
                    + Nueva produccion
                  </button>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                <div className="bg-white rounded-lg shadow overflow-x-auto">
                  <ToastContainer />
                  <table className="w-full">
                    <thead>
                      <tr className="bg-gray-50 text-left">
                        <th onClick={() => handleSort('order')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Nro. Orden {sortField === 'order' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('name')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600">Nombre {sortField === 'name' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('estimated_start_date')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Inicio estimado {sortField === 'estimated_start_date' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('estimated_end_date')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Fin Estimado {sortField === 'estimated_end_date' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('price')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Precio {sortField === 'price' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('status')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600  hidden md:table-cell">Estado  {sortField === 'status' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th className="py-3 px-4 font-semibold text-sm text-gray-600">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {produccionesActuales.map((produccion) => (
                        <tr key={produccion.id} className="border-b hover:bg-gray-50">
                          <td className="py-3 px-4 text-sm hidden md:table-cell">#{produccion.order}</td>
                          <td className="py-3 px-4 text-sm">{produccion.name}</td>    
                          <td className="py-3 px-4 text-sm hidden md:table-cell">{produccion.estimated_start_date}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell">{produccion.estimated_end_date}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell">${produccion.price}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell">
                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusStyle(produccion.status)}`}>
                              {getStatusLabel(produccion.status)}
                            </span>
                          </td>
                          <td className="py-3 px-4 md:px-6">
                            <div className="hidden md:flex space-x-2">
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#0984e3' }} 
                                onClick={() => handleViewDetails(produccion)}
                              >
                                <FaSearch />
                              </button>
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#f1c40f' }}  
                                onClick={() => handleEditProduccion(produccion)}
                              >
                                <FaEdit />
                              </button>
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#e74c3c' }}  
                                onClick={() => handleDelete(produccion.id)}
                              >
                                <FaTrash />
                              </button>
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#27ae60' }}  
                                onClick={() => handleUpdateStatus(produccion)}
                              >
                                <FaSync />
                              </button>
                            </div>
                            <div className="md:hidden">
                              <button
                                  className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                  onClick={(e) => handleMenuOpen(e, produccion.id)}
                                >
                                  <FaEllipsisV className="text-xl" />
                                  </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </>
              )}
              <div className="mt-4 flex justify-center items-center space-x-2">
                {paginaActual > 1 && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual - 1)}
                  >
                    Anterior
                  </button>
                )}
                <div className="flex space-x-2">
                  {[...Array(totalPaginas)].map((_, index) => (
                    <button
                      key={index + 1}
                      className={`px-3 py-1 rounded-md ${
                        paginaActual === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-gray-700 border hover:bg-gray-100'
                      }`}
                      onClick={() => cambiarPagina(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                {paginaActual < totalPaginas && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual + 1)}
                  >
                    Siguiente
                  </button>
                )}
              </div>
            </>
          }
        />
      </Routes>
      {/* Menú desplegable para móviles */}
      {openMenuId && createPortal(
        <div
          ref={menuRef}
          style={{
            position: 'absolute',
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
            zIndex: 1000,
          }}
        >
          <div className="bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleViewDetails(produccionesActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaSearch className="mr-2" /> Ver detalles
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleEditProduccion(produccionesActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaEdit className="mr-2" /> Editar
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleDelete(openMenuId);
                  handleMenuClose();
                }}
              >
                <FaTrash className="mr-2" /> Eliminar
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleUpdateStatus(produccionesActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaSync className="mr-2" /> Actualizar estado
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
}