'use client'

import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Shipment {
  id: string;
  estimated_delivery_date: string;
  details: string;
  address: string;
}

interface EditarOrdenProps {
  editingOrden: {
    id: number;
    client: { django_user: { id: number } };
    date: string;
    details: string;
    name: string;
    shipment: Shipment | null;
  };
  onClose: () => void;
  onOrdenEditada: () => void;
}

export default function EditarOrden({ editingOrden, onClose, onOrdenEditada }: EditarOrdenProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [includeShipment, setIncludeShipment] = useState(false);
  const [formData, setFormData] = useState({
    client: '',
    date: '',
    details: '',
    name: '',
    shipment: {
      id: '',
      estimated_delivery_date: '',
      details: '',
      address: '',
    },
  });

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/clients/', {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
          },
        });

        if (response.ok) {
          const clientsData = await response.json();
          setClients(clientsData);
        } else {
          toast.error('Error al cargar la lista de clientes');
        }
      } catch (error) {
        toast.error('Error al intentar obtener los clientes');
      }
    };

    fetchClients();
    setIsOpen(true);
  }, []);

  useEffect(() => {
    console.log("aaaa")
    if (editingOrden) {
      console.log(editingOrden.shipments[0])
      const formattedDate = editingOrden.date
        ? new Date(editingOrden.date).toISOString().split('T')[0]
        : '';
      setFormData({
        date: formattedDate,
        client: editingOrden.client.django_user.id.toString(),
        details: editingOrden.details || '',
        name: editingOrden.name || '',
        shipment: editingOrden.shipments[0]
          ? {
              id: editingOrden.shipments[0].id || '',
              estimated_delivery_date: editingOrden.shipments[0].estimated_delivery_date
                ? new Date(editingOrden.shipments[0].estimated_delivery_date).toISOString().split('T')[0]
                : '',
              details: editingOrden.shipments[0].details || '',
              address: editingOrden.shipments[0].address || '',
            }
          : {
              id: '',
              estimated_delivery_date: '',
              details: '',
              address: '',
            },
      });
      setIncludeShipment(!!editingOrden.shipments[0]);
    }
  }, [editingOrden]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
  
    if (name === 'date') {
      // Actualizar tanto la fecha de la orden como la fecha estimada de entrega del envío
      setFormData((prevData) => ({
        ...prevData,
        date: value,
        shipment: {
          ...prevData.shipment,
          estimated_delivery_date: value, // Sincronizar la fecha de entrega estimada con la fecha de la orden
        },
      }));
    } else if (name.startsWith('shipment.')) {
      setFormData((prevData) => ({
        ...prevData,
        shipment: {
          ...prevData.shipment,
          [name.split('.')[1]]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token');
      const adjustedFormData = {
        ...formData,
        date: formData.date ? `${formData.date}T00:00:00` : '',
      };

      // Update the order
      const orderResponse = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/orders/?id=${editingOrden.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(adjustedFormData),
      });

      if (!orderResponse.ok) {
        const errorData = await orderResponse.json();
        throw new Error(errorData.description_es || 'Actualización de orden fallida');
      }

      // Handle shipment
      console.log("SHIPMENT")
      console.log(includeShipment)
      if (includeShipment) {
        const shipmentData = {
          ...formData.shipment,
          order: editingOrden.id,
          estimated_delivery_date: formData.date || formData.shipment.estimated_delivery_date,
        };

        const shipmentMethod = shipmentData.id ? 'PUT' : 'POST';
        const shipmentUrl = shipmentData.id
          ? `https://api.agromaderaslaboulaye.com.ar/api/shipment/?id=${shipmentData.id}`
          : 'https://api.agromaderaslaboulaye.com.ar/api/shipment/';

        const shipmentResponse = await fetch(shipmentUrl, {
          method: shipmentMethod,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify(shipmentData),
        });

        if (!shipmentResponse.ok) {
          const errorData = await shipmentResponse.json();
          throw new Error(errorData.description_es || 'Actualización/Creación de envío fallida');
        }
      }

      toast.success('Orden actualizada con éxito' + (includeShipment ? ' y envío asociado' : ''));
      await new Promise(resolve => setTimeout(resolve, 1500));
      onOrdenEditada();
      handleClose();
    } catch (error) {
      toast.error(error.message || 'Error al intentar actualizar la orden y/o envío');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className={`inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <ToastContainer />
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Editar Orden</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="client" className="block text-sm font-medium text-gray-700">
                  Cliente
                </label>
                <select
                  id="client"
                  name="client"
                  value={formData.client}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                >
                  <option value="">Selecciona un cliente</option>
                  {clients.map((client: any) => (
                    <option key={client.django_user.id} value={client.django_user.id}>
                      {client.full_name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                  Fecha de entrega
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                />
              </div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Nombre orden
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="details" className="block text-sm font-medium text-gray-700">
                Detalles
              </label>
              <textarea
                id="details"
                name="details"
                value={formData.details}
                onChange={handleChange}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out h-32"
                rows={4}
                required
              />
            </div>
            <div>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={includeShipment}
                  onChange={(e) => setIncludeShipment(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
                <span className="ml-2 text-gray-700">Incluir envío</span>
              </label>
            </div>
            {includeShipment && (
              <div className="space-y-4">
                <div>
                  <label htmlFor="shipment.address" className="block text-sm font-medium text-gray-700">
                    Dirección de entrega
                  </label>
                  <input
                    type="text"
                    id="shipment.address"
                    name="shipment.address"
                    value={formData.shipment.address}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                    required={includeShipment}
                  />
                </div>
                <div>
                  <label htmlFor="shipment.details" className="block text-sm font-medium text-gray-700">
                    Detalles del envío
                  </label>
                  <textarea
                    id="shipment.details"
                    name="shipment.details"
                    value={formData.shipment.details}
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out h-32"
                    rows={4}
                    required={includeShipment}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={async () => {
                      if (isSubmitting) return;
                      setIsSubmitting(true);
                      
                      try {
                        const token = localStorage.getItem('token');

                        if (formData.shipment.id) {
                          // Hacer la solicitud DELETE al endpoint del envío
                          const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/shipment/?id=${formData.shipment.id}`, {
                            method: 'DELETE',
                            headers: {
                              'Authorization': `Token ${token}`,
                            },
                          });

                          if (!response.ok) {
                            const errorData = await response.json();
                            throw new Error(errorData.description_es || 'Error al intentar eliminar el envío');
                          }

                          toast.success('Envío eliminado con éxito');
                        } else {
                          toast.error('No hay un envío asociado para eliminar');
                        }

                        await new Promise(resolve => setTimeout(resolve, 1500)); // Pequeño delay antes de cerrar y refrescar
                        onOrdenEditada();
                        handleClose();
                      } catch (error) {
                        toast.error(error.message || 'Error al intentar eliminar el envío');
                      } finally {
                        setIsSubmitting(false);
                      }
                    }}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200 ease-in-out"
                  >
                    Eliminar envío
                  </button>
                </div>
              </div>
            )}
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={handleClose}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200 ease-in-out"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                  isSubmitting ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Guardando...' : 'Guardar cambios'}
              </button>
            </div>
          </form>
        
        </div>
      </div>
    </div>
  );
}