import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Component({ editingClient, onClose, onClienteEditado }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    birth_date: '',
    address: '',
    id_number: '',
    phone: '',
    gender: 'male',
    city: '',
    state: '',
    country: '',
    cuit: '',
  });

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const [countriesResponse] = await Promise.all([
          fetch('https://api.agromaderaslaboulaye.com.ar/api/countries/', {
            headers: { 'Authorization': `Token ${token}` },
          }),
        ]);

        if (countriesResponse.ok) {
          setCountries(await countriesResponse.json());
        } else {
          console.error('Error al obtener los países');
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };

    fetchData();
  }, []);

  const fetchStates = async (countryId) => {
    if (countryId) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/states/?id=${countryId}`, {
          headers: { 'Authorization': `Token ${token}` },
        });
        if (response.ok) {
          const statesData = await response.json();
          setStates(statesData);
        } else {
          console.error('Error al obtener los estados');
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    } else {
      setStates([]);
    }
  };
  
  const fetchCities = async (stateId) => {
  if (stateId) {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/cities/?id=${stateId}`, {
        headers: { 'Authorization': `Token ${token}` },
      });
      if (response.ok) {
        const citiesData = await response.json();
        setCities(citiesData);
      } else {
        console.error('Error al obtener las ciudades');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  } else {
    setCities([]);
  }
  };

  useEffect(() => {
    if (editingClient) {
      setFormData({
        email: editingClient.email || '',
        first_name: editingClient.django_user.first_name || '',
        last_name: editingClient.django_user.last_name || '',
        birth_date: editingClient.birth_date || '',
        address: editingClient.address || '',
        id_number: editingClient.id_number || '',
        cuit: editingClient.cuit || '',
        phone: editingClient.phone || '',
        gender: editingClient.gender || 'male',
        city: editingClient.city?.id || '',
        state: editingClient.city?.state?.id || '',
        country: editingClient.city?.state?.country?.id || '',
      });
      // Cargar estados y ciudades iniciales
      if (editingClient.city?.state?.country?.id) {
        fetchStates(editingClient.city.state.country.id).then(() => {
          if (editingClient.city?.state?.id) {
            fetchCities(editingClient.city.state.id);
          }
        });
      }
    }
  }, [editingClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cleanedFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => typeof value === 'string' && value.trim() !== '')
    );

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/clients/?user_id=${editingClient.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(cleanedFormData),
      });

      if (response.ok) {
        toast.success('Cliente actualizado con éxito');
        await sleep(1500);
        onClienteEditado();
        handleClose();
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.description_es || 'Actualizacion de cliente fallida';
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error('Error al intentar actualizar el cliente');
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300); // Delay closing to allow animation to complete
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className={`inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <ToastContainer />
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Editar Cliente</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                  Nombre
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                  Apellido
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="birth_date" className="block text-sm font-medium text-gray-700">
                  Fecha de nacimiento
                </label>
                <input
                  type="date"
                  id="birth_date"
                  name="birth_date"
                  value={formData.birth_date}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  Direccion
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="cuit" className="block text-sm font-medium text-gray-700">
                  CUIT
                </label>
                <input
                  type="text"
                  id="cuit"
                  name="cuit"
                  value={formData.cuit}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="id_number" className="block text-sm font-medium text-gray-700">
                  DNI
                </label>
                <input
                  type="text"
                  id="id_number"
                  name="id_number"
                  value={formData.id_number}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Telefono
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
              <div>
                <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                  Genero
                </label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                >
                  <option value="male">Masculino</option>
                  <option value="female">Femenino</option>
                </select>
              </div>
              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Pais
                </label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                >
                  <option value="">Seleccionar País</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                  Provincia
                </label>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                >
                  <option value="">Seleccionar Provincia</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  Ciudad
                </label>
                <select
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                >
                  <option value="">Seleccionar Ciudad</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={handleClose}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200 ease-in-out"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
              >
                Guardar Cambios
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}