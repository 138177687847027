import React, { useState, useEffect, useRef } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

interface ProductionDetailsProps {
  produccion: {
    name: string;
    id: number;
    details: string;
    estimated_start_date: string;
    estimated_end_date: string;
    price: number;
    status: string;
    order: number;
  };
  onClose: () => void;
}

interface ProductionHistory {
  status: string;
  date: string;
}

export default function ProductionDetails({ produccion, onClose }: ProductionDetailsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [productionHistory, setProductionHistory] = useState<ProductionHistory[]>([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(true);
    fetchProductionHistory();
  }, []);

  useEffect(() => {
    const checkScroll = () => {
      if (timelineRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = timelineRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
      }
    };

    checkScroll();
    timelineRef.current?.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    return () => {
      timelineRef.current?.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, [productionHistory]);

  const fetchProductionHistory = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/production/?id=${produccion.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const history = data.productionhistory_set.map((item: any) => ({
        status: item.status,
        date: item.date
      }));
      setProductionHistory(history);
    } catch (error) {
      console.error('Error fetching production history:', error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(amount);
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'confirm':
        return 'bg-green-100 text-green-800';
      case 'in_production':
        return 'bg-blue-100 text-blue-800';
      case 'ready_for_shipment':
        return 'bg-purple-100 text-purple-800';
      case 'ended':
        return 'bg-gray-100 text-gray-800';
      case 'canceled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'confirm':
        return 'Confirmado';
      case 'in_production':
        return 'En producción';
      case 'ready_for_shipment':
        return 'Listo para entrega';
      case 'ended':
        return 'Terminada';
      case 'canceled':
        return 'Cancelado';
      default:
        return status;
    }
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (timelineRef.current) {
      const scrollAmount = timelineRef.current.clientWidth / 2;
      timelineRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const renderTimeline = () => {
    const sortedHistory = [...productionHistory].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const currentStatusIndex = sortedHistory.findIndex(item => item.status === produccion.status);

    return (
      <div className="mt-8 relative">
        <h3 className="text-lg font-semibold mb-4">Seguimiento</h3>
        <div className="relative">
          <div className="absolute left-0 top-2 h-1 w-full bg-gray-200"></div>
          <div 
            ref={timelineRef}
            className="flex overflow-x-auto scrollbar-hide relative"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {sortedHistory.map((item, index) => (
              <div key={index} className="flex-shrink-0 w-32 flex flex-col items-center">
                <div className={`w-4 h-4 rounded-full ${index === currentStatusIndex ? 'bg-green-500' : index < currentStatusIndex ? 'bg-green-300' : 'bg-gray-300'}`}></div>
                <p className="mt-2 text-xs font-medium text-center">{getStatusLabel(item.status)}</p>
                <p className="mt-1 text-xs text-gray-500">{formatDate(item.date)}</p>
              </div>
            ))}
          </div>
          {canScrollLeft && (
            <button 
              onClick={() => handleScroll('left')} 
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-md p-1"
            >
              <ChevronLeft className="w-6 h-6 text-gray-600" />
            </button>
          )}
          {canScrollRight && (
            <button 
              onClick={() => handleScroll('right')} 
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-md p-1"
            >
              <ChevronRight className="w-6 h-6 text-gray-600" />
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 py-8 text-center">
        <div className={`inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-900">{produccion.name} #{produccion.id}</h2>
            <div className="flex items-center">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(produccion.status)} mr-2`}>
                {getStatusLabel(produccion.status)}
              </span>
              <button onClick={handleClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="mt-4 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">Nro. Orden</label>
                <p className="text-sm text-gray-700">#{produccion.order}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500 mb-1">Precio</label>
                <p className="text-sm font-semibold">{formatCurrency(produccion.price)}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-500">Fecha estimada inicio</label>
                <p className="mt-1 text-sm font-semibold">{formatDate(produccion.estimated_start_date)}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Fecha estimada fin</label>
                <p className="mt-1 text-sm font-semibold">{formatDate(produccion.estimated_end_date)}</p>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Detalle</label>
              <p className="text-sm text-gray-700">{produccion.details}</p>
            </div>
            {renderTimeline()}
          </div>
        </div>
      </div>
    </div>
  );
}