import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/Login.tsx';
import Dashboard from './components/Dashboard.tsx';
import CrearUsuario from './components/usuarios/CrearUsuario.tsx';
import PasswordReset from './components/PasswordReset.tsx';

function App() {
  const location = useLocation();

  // Efecto para cambiar el título de la pestaña dependiendo de la ruta actual
  useEffect(() => {
    switch (location.pathname) {
      case '/login':
        document.title = 'Agromaderas';
        break;
      case '/dashboard':
        document.title = 'Agromaderas';
        break;
      case '/dashboard/crear-usuario':
        document.title = 'Agromaderas';
        break;
      default:
        document.title = 'Agromaderas';
        break;
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Login />} />
      <Route path="/dashboard/crear-usuario" element={<CrearUsuario />} />
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
