import React, { useState } from 'react';
import Sidebar from './Sidebar.tsx';
import Usuarios from './usuarios/Usuarios.tsx';
import Producciones from './producciones/Producciones.tsx';
import Ordenes from './ordenes/Ordenes.tsx';
import Envios from './envios/Envios.tsx';
import Clientes from './clientes/Clientes.tsx';
import CrearUsuario from './usuarios/CrearUsuario.tsx';
import CrearCliente from './clientes/CrearCliente.tsx';
import CrearProduccion from './producciones/CrearProduccion.tsx';
import CrearOrden from './ordenes/CrearOrden.tsx'
import CrearEnvio from './envios/CrearEnvio.tsx';
import Estadisticas from './Estadisticas.tsx';

export default function Dashboard() {
  const [selectedOption, setSelectedOption] = useState('Estadisticas');
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isCrearUsuarioOpen, setCrearUsuarioOpen] = useState(false);
  const [isCrearClienteOpen, setCrearClienteOpen] = useState(false);
  const [isCrearProduccionOpen, setCrearProduccionOpen] = useState(false);
  const [isCrearOrdenOpen, setCrearOrdenOpen] = useState(false);
  const [isCrearEnvioOpen, setCrearEnvioOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  const handleCrearUsuarioOpen = () => setCrearUsuarioOpen(true);
  const handleCrearUsuarioClose = () => setCrearUsuarioOpen(false);
  const handleCrearClienteOpen = () => setCrearClienteOpen(true);
  const handleCrearClienteClose = () => setCrearClienteOpen(false);
  const handleCrearProduccionOpen = () => setCrearProduccionOpen(true);
  const handleCrearProduccionClose = () => setCrearProduccionOpen(false);
  const handleCrearOrdenOpen = () => setCrearOrdenOpen(true);
  const handleCrearOrdenClose = () => setCrearOrdenOpen(false);
  const handleCrearEnvioOpen = () => setCrearEnvioOpen(true);
  const handleCrearEnvioClose = () => setCrearEnvioOpen(false);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar 
        onSelect={setSelectedOption} 
        selected={selectedOption} 
        isOpen={isSidebarOpen}
        onToggle={toggleSidebar}
      />
      
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className={`
          flex-1 overflow-y-auto p-6 bg-gray-100
          ${isCrearUsuarioOpen || isCrearProduccionOpen || isCrearClienteOpen || isCrearOrdenOpen ? 'filter blur-sm' : ''}
        `}>
          {selectedOption === 'Estadisticas' && <Estadisticas onCrearUsuario={handleCrearUsuarioOpen} />}
          {selectedOption === 'Usuarios' && <Usuarios onCrearUsuario={handleCrearUsuarioOpen} />}
          {selectedOption === 'Clientes' && <Clientes onCrearCliente={handleCrearClienteOpen} />}
          {selectedOption === 'Ordenes' && <Ordenes onCrearOrden={handleCrearOrdenOpen}/>}
          {selectedOption === 'Producciones' && <Producciones onCrearProduccion={handleCrearProduccionOpen} />}
          {selectedOption === 'Envios' && <Envios onCrearEnvio={handleCrearEnvioOpen} />}
        </main>
      </div>

      {isCrearUsuarioOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative z-10">
            <CrearUsuario onCancel={handleCrearUsuarioClose} />
          </div>
        </div>
      )}

      {isCrearClienteOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative z-10">
            <CrearCliente onCancel={handleCrearClienteClose} />
          </div>
        </div>
      )}

      {isCrearOrdenOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative z-10">
            <CrearOrden onCancel={handleCrearOrdenClose} />
          </div>
        </div>
      )}

      {isCrearProduccionOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative z-10">
            <CrearProduccion onCancel={handleCrearProduccionClose} />
          </div>
        </div>
      )}

      {isCrearEnvioOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative z-10">
            <CrearEnvio onCancel={handleCrearEnvioClose} />
          </div>
        </div>
      )}
    </div>
  );
}
