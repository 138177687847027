import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { FaEdit, FaTrash, FaSearch, FaEllipsisV, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { createPortal } from 'react-dom';
import { Menu } from '@headlessui/react'
import CrearOrden from './CrearOrden.tsx';
import EditarOrden from './EditarOrden.tsx';
import ModalConfirmacion from '../ModalConfirmacion.tsx';
import OrdenDetails from './OrdenDetails.tsx';
import { Loader2 } from "lucide-react"
import { ToastContainer, toast } from 'react-toastify';

const getStatusStyle = (status) => {
  switch (status) {
    case 'pending':
      return 'bg-yellow-100 text-yellow-800';
    case 'in_progress':
      return 'bg-blue-100 text-blue-800';
    case 'ready':
      return 'bg-green-100 text-green-800';
    case 'completed':
      return 'bg-gray-100 text-gray-800';
    case 'canceled':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const getStatusLabel = (status) => {
  switch (status) {
    case 'pending':
      return 'Pendiente';
    case 'in_progress':
      return 'En producción';
    case 'ready':
      return 'Lista';
    case 'completed':
      return 'Completada';
    case 'canceled':
      return 'Cancelada';
    default:
      return status;
  }
};

export default function Ordenes() {
  const navigate = useNavigate();
  const [ordenes, setOrdenes] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('details');
  const [showCrearOrden, setShowCrearOrden] = useState(false);
  const [showEditarOrden, setShowEditarOrden] = useState(false);
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [ordenAEliminar, setOrdenAEliminar] = useState(null);
  const [selectedOrden, setSelectedOrden] = useState(null);
  const [editingOrden, setEditingOrden] = useState(null);
  const [sortField, setSortField] = useState('name'); // Campo por el cual ordenar
  const [sortOrder, setSortOrder] = useState('asc'); // Orden ascendente o descendente
  const ordenesPorPagina = 10;
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);

  const fetchOrdenes = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/orders/', {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Error al obtener las ordenes');
      const data = await response.json();
      const ordenesFormateadas = data.map((orden) => {
        // Sumar el precio total de las producciones asociadas a la orden
        const totalProductionsPrice = orden.productions.reduce((acc, production) => {
          return production.status !== 'canceled' ? acc + production.price : acc;
        }, 0);

        return {
          id: orden.id,
          name: orden.name,
          full_name: orden.client.full_name,
          status: orden.status,
          details: orden.details,
          created_at: orden.created_at,
          date: orden.date,
          productions: orden.productions,
          shipment_id: orden.shipments.length > 0 ? orden.shipments[0].id : 'No hay envio registrado',
          shipment_address: orden.shipments.length > 0 ? orden.shipments[0].address : 'No hay envio registrado',
          shipment_detail: orden.shipments.length > 0 ? orden.shipments[0].details : 'No hay envio registrado',
          shipment_estimated_date: orden.shipments.length > 0 ? orden.shipments[0].estimated_delivery_date : 'No hay envio registrado',
          shipment_real_date: orden.shipments.length > 0 
            ? (orden.shipments[0].real_delivery_date ?? 'Fecha no disponible') // Usar el operador nullish coalescing
            : 'No hay envío registrado',
          totalProductionsPrice, // Añadir el total de las producciones
          ...orden  
        };
      });
      setOrdenes(ordenesFormateadas);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdenes();
  }, []);

  const handleOrdenCreada = () => {
    fetchOrdenes(); // Actualiza la lista de envíos
    setShowCrearOrden(false); // Cierra el modal de crear envío
  };

  const handleOrdenEditada = () => {
    fetchOrdenes(); // Actualiza la lista de envíos
    setShowEditarOrden(false); // Cierra el modal de crear envío
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Cambia el orden si es el mismo campo
    } else {
      setSortField(field); // Cambia el campo de ordenamiento
      setSortOrder('asc'); // Por defecto, orden ascendente
    }
  };

  const ordenar = (ordenes) => {
    return ordenes.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleCrearOrden = () => {
    setEditingOrden(null);
    setShowCrearOrden(true);
  };

  const handleEditOrden = (orden) => {
    setEditingOrden(orden);
    setShowEditarOrden(true);
  };

  const cambiarPagina = (nuevaPagina) => {
    setPaginaActual(nuevaPagina);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchFieldChange = (e) => {
    setSearchField(e.target.value);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const ordenesFiltradas = ordenar(
    ordenes.filter((orden) =>
      orden[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indiceUltimoOrden = paginaActual * ordenesPorPagina;
  const indicePrimerOrden = indiceUltimoOrden - ordenesPorPagina;
  const ordenesActuales = ordenesFiltradas.slice(indicePrimerOrden, indiceUltimoOrden);

  const totalPaginas = Math.ceil(ordenesFiltradas.length / ordenesPorPagina);

  const confirmarEliminacion = async () => {
    if (ordenAEliminar) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/orders/?id=${ordenAEliminar}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.description_es || 'Eliminación de orden fallida';
          toast.error(errorMessage);
          // No eliminamos la orden del frontend si la respuesta no es ok
        } else {
          // Solo eliminamos la orden del frontend si la respuesta es ok
          setOrdenes(ordenes.filter((orden) => orden.id !== ordenAEliminar));
          toast.success('Orden eliminada con éxito');
        }
        setShowModalConfirmacion(false);
        setOrdenAEliminar(null);
      } catch (error) {
        console.error('Error:', error);
        toast.error('Error al intentar eliminar la orden');
      }
    }
  };

  const handleDelete = (ordenId) => {
    setOrdenAEliminar(ordenId);
    setShowModalConfirmacion(true);
  };

  const cancelarEliminacion = () => {
    setShowModalConfirmacion(false);
    setOrdenAEliminar(null);
  };

  const handleViewDetails = (orden) => {
    console.log(orden)
    setSelectedOrden(orden);
  };

  const handleMenuOpen = (event, envioId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOpenMenuId(envioId);
  };

  const handleMenuClose = useCallback(() => {
    setOpenMenuId(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleMenuClose]);

  function Spinner() {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    )
  }

  return (
    <div className="p-4 md:p-6 max-w-6xl mx-auto relative bg-gray-100 min-h-screen">
      {showCrearOrden && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CrearOrden
            onClose={() => setShowCrearOrden(false)}
            onOrdenCreada={handleOrdenCreada}
            editingOrden={editingOrden}
          />
        </div>
      )}
      {showEditarOrden && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <EditarOrden
            onClose={() => setShowEditarOrden(false)}
            onOrdenEditada={handleOrdenEditada}
            editingOrden={editingOrden}
          />
        </div>
      )}
      {showModalConfirmacion && (
        <ModalConfirmacion
          mensaje="¿Estás seguro de que quieres eliminar esta orden?"
          onConfirm={confirmarEliminacion}
          onCancel={cancelarEliminacion}
        />
      )}
      {selectedOrden && (
        <OrdenDetails
          orden={selectedOrden}
          onClose={() => setSelectedOrden(null)}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
                <h1 className="text-3xl font-bold">Ordenes</h1>
                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <div className="relative w-full md:w-64">
                    <input
                      type="text"
                      placeholder="Buscar orden"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="border rounded-md py-2 px-3 pr-10 w-full"
                    />
                    <svg
                      className="absolute right-3 top-2.5 h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <button
                    className="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-200 w-full md:w-auto"
                    onClick={handleCrearOrden}
                  >
                    + Nueva orden
                  </button>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                <div className="bg-white rounded-lg shadow overflow-x-auto">
                  <ToastContainer />
                  <table className="w-full">
                    <thead>
                      <tr className="bg-gray-50 text-left">
                        <th onClick={() => handleSort('id')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Nro. Orden {sortField === 'id' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('name')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600">Nombre {sortField === 'name' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('full_name')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Cliente {sortField === 'full_name' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('created_at')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Fecha de creacion {sortField === 'created_at' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('date')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Fecha de entrega {sortField === 'date' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('totalProductionsPrice')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Precio total {sortField === 'price' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th onClick={() => handleSort('status')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600  hidden md:table-cell">Estado {sortField === 'status' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                        <th className="py-3 px-4 font-semibold text-sm text-gray-600">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordenesActuales.map((orden) => (
                        <tr key={orden.id} className="border-b hover:bg-gray-50">
                          <td className="py-3 px-4 text-sm hidden md:table-cell">#{orden.id}</td>
                          <td className="py-3 px-4 text-sm">{orden.name}</td>    
                          <td className="py-3 px-4 text-sm hidden md:table-cell">{orden.full_name}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell">{formatDate(orden.created_at)}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell">{formatDate(orden.date)}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell font-bold">${orden.totalProductionsPrice}</td>
                          <td className="py-3 px-4 text-sm hidden md:table-cell">
                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusStyle(orden.status)}`}>
                              {getStatusLabel(orden.status)}
                            </span>
                          </td>
                          <td className="py-3 px-4 md:px-6">
                            <div className="hidden md:flex space-x-2">
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#0984e3' }} 
                                onClick={() => handleViewDetails(orden)}
                              >
                                <FaSearch />
                              </button>
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#f1c40f' }}  
                                onClick={() => handleEditOrden(orden)}
                              >
                                <FaEdit />
                              </button>
                              <button
                                className="hover:opacity-80"
                                style={{ color: '#e74c3c' }}  
                                onClick={() => handleDelete(orden.id)}
                              >
                                <FaTrash />
                              </button>
                            </div>
                            <div className="md:hidden">
                            <button
                                  className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                  onClick={(e) => handleMenuOpen(e, orden.id)}
                                >
                                  <FaEllipsisV className="text-xl" />
                                  </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </>
              )}
              <div className="mt-4 flex justify-center items-center space-x-2">
                {paginaActual > 1 && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual - 1)}
                  >
                    Anterior
                  </button>
                )}
                <div className="flex space-x-2">
                  {[...Array(totalPaginas)].map((_, index) => (
                    <button
                      key={index + 1}
                      className={`px-3 py-1 rounded-md ${
                        paginaActual === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-gray-700 border hover:bg-gray-100'
                      }`}
                      onClick={() => cambiarPagina(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                {paginaActual < totalPaginas && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual + 1)}
                  >
                    Siguiente
                  </button>
                )}
              </div>
            </>
          }
        />
      </Routes>
      {/* Menú desplegable para móviles */}
      {openMenuId && createPortal(
        <div
          ref={menuRef}
          style={{
            position: 'absolute',
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
            zIndex: 1000,
          }}
        >
          <div className="bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleViewDetails(ordenesActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaSearch className="mr-2" /> Ver detalles
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleEditOrden(ordenesActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaEdit className="mr-2" /> Editar
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleDelete(openMenuId);
                  handleMenuClose();
                }}
              >
                <FaTrash className="mr-2" /> Eliminar
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
}