import React, { useState, useEffect } from 'react'
import { ShoppingCart, DollarSign, Users, BarChart, Calendar } from 'lucide-react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Loader2 } from "lucide-react"

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title)

interface Order {
  id: number;
  name: string;
  full_name: string;
  status: string;
  details: string;
  created_at: string;
  date: string;
  productions: any[];
  shipment_address: string;
  shipment_detail: string;
  shipment_estimated_date: string;
  shipment_real_date: string;
  totalProductionsPrice: number;
}

interface Client {
  id: number;
  full_name: string;
  email: string;
}

export default function Estadisticas() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set the initial date range to the current month
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    
    setStartDate(firstDayOfMonth);
    setEndDate(lastDayOfMonth);

    setIsLoading(true);
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/orders/', {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Error al obtener las ordenes');
        const data = await response.json();
        const formattedOrders = data.map((orden: any) => {
          const totalProductionsPrice = orden.productions.reduce((acc: number, production: any) => {
            return production.status === 'ended' ? acc + production.price : acc;
          }, 0);

          return {
            id: orden.id,
            name: orden.name,
            full_name: orden.client.full_name,
            status: orden.status,
            details: orden.details,
            created_at: orden.created_at,
            date: orden.date,
            productions: orden.productions,
            shipment_address: orden.shipments.length > 0 ? orden.shipments[0].address : 'No hay envio registrado',
            shipment_detail: orden.shipments.length > 0 ? orden.shipments[0].details : 'No hay envio registrado',
            shipment_estimated_date: orden.shipments.length > 0 ? orden.shipments[0].estimated_delivery_date : 'No hay envio registrado',
            shipment_real_date: orden.shipments.length > 0 
              ? (orden.shipments[0].real_delivery_date ?? 'Fecha no disponible')
              : 'No hay envío registrado',
            totalProductionsPrice,
            ...orden  
          };
        });
        setOrders(formattedOrders);
      } catch (error) {
        console.error('Error:', error);
        setError('Error al cargar las órdenes');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/clients/', {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Error al obtener los clientes');
        const data = await response.json();
        const formattedClients = data.map((cliente: any) => ({
          id: cliente.django_user.id,
          full_name: cliente.full_name,
          email: cliente.django_user.email,
          ...cliente
        }));
        setClients(formattedClients);
      } catch (error) {
        console.error('Error:', error);
        setError('Error al cargar los clientes');
      }
    };

    Promise.all([fetchOrders(), fetchClients()])
  }, []);

  const totalOrders = orders.length;
  const totalIncome = orders.reduce((acc, order) => acc + order.totalProductionsPrice, 0);
  const completedOrders = orders.filter(order => order.status === 'completed').length;
  const completionRate = totalOrders > 0 ? (completedOrders / totalOrders) * 100 : 0;
  const activeClients = clients.length;

  const ordersByStatus = orders.reduce((acc, order) => {
    acc[order.status] = (acc[order.status] || 0) + 1;
    return acc;
  }, {});

  const filteredOrders = orders.filter(order => {
    const orderDate = new Date(order.date);
    return orderDate >= startDate && orderDate <= endDate;
  });

  const monthlyIncome = filteredOrders.reduce((acc, order) => {
    if (order.status === 'completed') {
      const month = new Date(order.date).toLocaleString('default', { month: 'long' });
      acc[month] = (acc[month] || 0) + order.totalProductionsPrice;
    }
    return acc;
  }, {});

  const monthlyIncomeData = {
    labels: Object.keys(monthlyIncome).reverse(),
    datasets: [
      {
        label: 'Ingresos Mensuales',
        data: Object.values(monthlyIncome).reverse(),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
  }

  function Spinner() {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    )
  }

  return (
    <div className="p-6 max-w-7xl mx-auto space-y-6">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <h1 className="text-3xl font-bold mb-4 sm:mb-0">Estadisticas</h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-blue-500 text-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-sm font-medium">Total de Órdenes</h2>
                <ShoppingCart size={24} />
              </div>
              <p className="text-4xl font-bold">{totalOrders}</p>
            </div>
            <div className="bg-green-500 text-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-sm font-medium">Ingresos Totales</h2>
                <DollarSign size={24} />
              </div>
              <p className="text-4xl font-bold">${totalIncome.toFixed(0)}</p>
            </div>
            <div className="bg-yellow-500 text-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-sm font-medium">Clientes</h2>
                <Users size={24} />
              </div>
              <p className="text-4xl font-bold">{activeClients}</p>
            </div>
            <div className="bg-red-500 text-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-sm font-medium">Tasa de Finalización</h2>
                <BarChart size={24} />
              </div>
              <p className="text-4xl font-bold">{completionRate.toFixed(1)}%</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold mb-4">Ordenes por estado</h2>
              <div className="space-y-4">
                <div className="flex items-center justify-between p-4 rounded-lg bg-yellow-100">
                  <span className="text-lg font-medium text-yellow-800">Pendientes</span>
                  <span className="text-2xl font-bold text-yellow-800">{ordersByStatus['pending'] || 0}</span>
                </div>
                <div className="flex items-center justify-between p-4 rounded-lg bg-blue-100">
                  <span className="text-lg font-medium text-blue-800">En producción</span>
                  <span className="text-2xl font-bold text-blue-800">{ordersByStatus['in_progress'] || 0}</span>
                </div>
                <div className="flex items-center justify-between p-4 rounded-lg bg-green-100">
                  <span className="text-lg font-medium text-green-800">Listas</span>
                  <span className="text-2xl font-bold text-green-800">{ordersByStatus['ready'] || 0}</span>
                </div>
                <div className="flex items-center justify-between p-4 rounded-lg bg-gray-100">
                  <span className="text-lg font-medium text-gray-800">Completadas</span>
                  <span className="text-2xl font-bold text-gray-800">{ordersByStatus['completed'] || 0}</span>
                </div>
                <div className="flex items-center justify-between p-4 rounded-lg bg-red-100">
                  <span className="text-lg font-medium text-red-800">Canceladas</span>
                  <span className="text-2xl font-bold text-red-800">{ordersByStatus['canceled'] || 0}</span>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold mb-4">Ingresos Mensuales</h2>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 space-y-4 sm:space-y-0">
                <div className="flex items-center space-x-2">
                  <Calendar size={20} />
                  <span>Filtrar por fecha:</span>
                </div>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
                  <div className="flex flex-col w-full sm:w-auto">
                    <label htmlFor="start-date" className="text-sm text-gray-600 mb-1">Fecha de inicio</label>
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      className="border rounded-md p-2 w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full sm:w-auto">
                    <label htmlFor="end-date" className="text-sm text-gray-600 mb-1">Fecha de fin</label>
                    <DatePicker
                      id="end-date"
                      selected={endDate}
                      onChange={(date: Date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      className="border rounded-md p-2 w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="h-[300px]">
                <Bar 
                  data={monthlyIncomeData} 
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          text: 'Ingresos ($)'
                        }
                      },
                      x: {
                        title: {
                          display: true,
                          text: 'Mes'
                        }
                      }
                    }
                  }} 
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}