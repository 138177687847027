import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

interface OrdenDetailsProps {
  orden: {
    client: string;
    name: string;
    id: number;
    details: string;
    created_at: string;
    date: string;
    status: string;
    shipment_address: string;
    shipment_detail: string;
    shipment_estimated_date: string;
    shipment_real_date: string;
  };
  onClose: () => void;
}

const OrdenDetails: React.FC<OrdenDetailsProps> = ({ orden, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(amount);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'in_progress':
        return 'bg-blue-100 text-blue-800';
      case 'ready':
        return 'bg-green-100 text-green-800';
      case 'completed':
        return 'bg-gray-100 text-gray-800';
      case 'canceled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  const getStatusLabel = (status) => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'in_progress':
        return 'En producción';
      case 'ready':
        return 'Lista';
      case 'completed':
        return 'Completada';
      case 'canceled':
        return 'Cancelada';
      default:
        return status;
    }
  };  

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 py-8 text-center">
        <div className={`inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-900">{orden.name} #{orden.id}</h2>
            <div className="flex items-center">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(orden.status)} mr-2`}>
                {getStatusLabel(orden.status)}
              </span>
              <button onClick={handleClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="mt-4 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-500">Fech de registro</label>
                <div className="mt-1 text-sm font-semibold">{formatDate(orden.created_at)}</div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Fecha de entrega estimada</label>
                <div className="mt-1 text-sm font-semibold">{formatDate(orden.date)}</div>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Cliente</label>
              <p className="text-sm text-gray-700">{orden.client.full_name}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Detalle</label>
              <p className="text-sm text-gray-700">{orden.details}</p>
            </div>
            <div>
              <label className="block text-xl font-bold text-gray-500 mb-1">Envio</label>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Direccion de envio</label>
              <p className="text-sm text-gray-700">{orden.shipment_address}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-2">Detalle de envio</label>
              <p className="text-sm text-gray-700">{orden.shipment_detail}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdenDetails;