import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { FaEdit, FaTrash, FaSearch, FaEllipsisV, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { createPortal } from 'react-dom';
import CrearEnvio from './CrearEnvio.tsx';
import EditarEnvio from './EditarEnvio.tsx';
import ModalConfirmacion from '../ModalConfirmacion.tsx';
import EnvioDetails from './EnvioDetails.tsx';
import { Loader2 } from "lucide-react";
import { ToastContainer, toast } from 'react-toastify';

export default function Envios() {
  const navigate = useNavigate();
  const [envios, setEnvios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginaActual, setPaginaActual] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('details');
  const [showCrearEnvio, setShowCrearEnvio] = useState(false);
  const [showEditarEnvio, setShowEditarEnvio] = useState(false);
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [envioAEliminar, setEnvioAEliminar] = useState(null);
  const [selectedEnvio, setSelectedEnvio] = useState(null);
  const [editingEnvio, setEditingEnvio] = useState(null);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [envioToUpdate, setEnvioToUpdate] = useState(null);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);
  const enviosPorPagina = 10;

  const fetchEnvios = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/shipment/', {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Error al obtener los envíos');
      const data = await response.json();
      const enviosFormateados = data.map((envio) => ({
        id: envio.id,
        created_at: envio.created_at,
        updated_at: envio.updated_at,
        deleted_at: envio.deleted_at,
        details: envio.details,
        address: envio.address,
        estimated_delivery_date: envio.estimated_delivery_date,
        real_delivery_date: envio.real_delivery_date,
        order: envio.order,
        ...envio
      }));
      setEnvios(enviosFormateados);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEnvios();
  }, []);

  const handleEnvioCreado = () => {
    fetchEnvios();
    setShowCrearEnvio(false);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const ordenar = (ordenes) => {
    return ordenes.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleCrearEnvio = () => {
    setEditingEnvio(null);
    setShowCrearEnvio(true);
  };

  const handleEditEnvio = (envio) => {
    setEditingEnvio(envio);
    setShowEditarEnvio(true);
  };

  const handleEnvioEditado = () => {
    fetchEnvios();
    setShowEditarEnvio(false);
  };

  const cambiarPagina = (nuevaPagina) => {
    setPaginaActual(nuevaPagina);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchFieldChange = (e) => {
    setSearchField(e.target.value);
  };

  const enviosFiltradas = ordenar(
    envios.filter((orden) =>
      orden[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const indiceUltimoProduccion = paginaActual * enviosPorPagina;
  const indicePrimerProduccion = indiceUltimoProduccion - enviosPorPagina;
  const enviosActuales = enviosFiltradas.slice(indicePrimerProduccion, indiceUltimoProduccion);

  const totalPaginas = Math.ceil(enviosFiltradas.length / enviosPorPagina);

  const confirmarEliminacion = async () => {
    if (envioAEliminar) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.agromaderaslaboulaye.com.ar/api/shipment/?id=${envioAEliminar}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.description_es || 'Eliminacion de envio fallida';
          toast.error(errorMessage);
        }
        setEnvios(envios.filter((envio) => envio.id !== envioAEliminar));
        setShowModalConfirmacion(false);
        setEnvioAEliminar(null);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleDelete = (envioId) => {
    setEnvioAEliminar(envioId);
    setShowModalConfirmacion(true);
  };

  const cancelarEliminacion = () => {
    setShowModalConfirmacion(false);
    setEnvioAEliminar(null);
  };

  const handleViewDetails = (envio) => {
    setSelectedEnvio(envio);
  };

  const handleUpdateStatus = (envio) => {
    console.log('Abriendo UpdateStatus para:', envio);
    setEnvioToUpdate(envio);
    setShowUpdateStatus(true);
  };

  const handleMenuOpen = (event, envioId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setOpenMenuId(envioId);
  };

  const handleMenuClose = useCallback(() => {
    setOpenMenuId(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleMenuClose]);

  function Spinner() {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    )
  }

  return (
    <div className="p-4 md:p-6 max-w-6xl mx-auto relative bg-gray-100 min-h-screen">
      {showCrearEnvio && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CrearEnvio
            onClose={() => setShowCrearEnvio(false)}
            onEnvioCreado={handleEnvioCreado}
            editingEnvio={editingEnvio}
          />
        </div>
      )}
      {showEditarEnvio && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <EditarEnvio
            onClose={() => setShowEditarEnvio(false)}
            onEnvioEditado={handleEnvioEditado}
            editingEnvio={editingEnvio}
          />
        </div>
      )}
      {showModalConfirmacion && (
        <ModalConfirmacion
          mensaje="¿Estás seguro de que quieres eliminar esta produccion?"
          onConfirm={confirmarEliminacion}
          onCancel={cancelarEliminacion}
        />
      )}
      {selectedEnvio && (
        <EnvioDetails
          envio={selectedEnvio}
          onClose={() => setSelectedEnvio(null)}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
                <h1 className="text-3xl font-bold">Envios</h1>
                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <div className="relative w-full md:w-64">
                    <input
                      type="text"
                      placeholder="Buscar envio"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="border rounded-md py-2 px-3 pr-10 w-full"
                    />
                    <svg
                      className="absolute right-3 top-2.5 h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <button
                    className="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-200 w-full md:w-auto"
                    onClick={handleCrearEnvio}
                  >
                    + Nuevo envio
                  </button>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <div className="bg-white rounded-lg shadow overflow-x-auto">
                    <ToastContainer />
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-50 text-left">
                          <th onClick={() => handleSort('order')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600">Nro. Orden {sortField === 'order' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                          <th onClick={() => handleSort('estimated_delivery_date')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Fecha de entrega estimada {sortField === 'estimated_delivery_date' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                          <th onClick={() => handleSort('address')} className="cursor-pointer py-3 px-4 font-semibold text-sm text-gray-600 hidden md:table-cell">Direccion {sortField === 'address' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}</th>
                          <th className="py-3 px-4 font-semibold text-sm text-gray-600">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enviosActuales.map((envio) => (
                          <tr key={envio.id} className="border-b hover:bg-gray-50">
                            <td className="py-3 px-4 text-sm">{envio.order}</td>    
                            <td className="py-3 px-4 text-sm hidden md:table-cell">{envio.estimated_delivery_date}</td>
                            <td className="py-3 px-4 text-sm hidden md:table-cell">{envio.address}</td>
                            <td className="py-3 px-4 md:px-6">
                              <div className="hidden md:flex space-x-2">
                                <button
                                  className="hover:opacity-80"
                                  style={{ color: '#0984e3' }} 
                                  onClick={() => handleViewDetails(envio)}
                                >
                                  <FaSearch />
                                </button>
                                <button
                                  className="hover:opacity-80"
                                  style={{ color: '#f1c40f' }}  
                                  onClick={() => handleEditEnvio(envio)}
                                >
                                  <FaEdit />
                                </button>
                                <button
                                  className="hover:opacity-80"
                                  style={{ color: '#e74c3c' }}  
                                  onClick={() => handleDelete(envio.id)}
                                >
                                  <FaTrash />
                                </button>
                              </div>
                              <div className="md:hidden">
                                <button
                                  className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                  onClick={(e) => handleMenuOpen(e, envio.id)}
                                >
                                  <FaEllipsisV className="text-xl" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <div className="mt-4 flex justify-center items-center space-x-2">
                {paginaActual > 1 && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual - 1)}
                  >
                    Anterior
                  </button>
                )}
                <div className="flex space-x-2">
                  {[...Array(totalPaginas)].map((_, index) => (
                    <button
                      key={index + 1}
                      className={`px-3 py-1 rounded-md ${
                        paginaActual === index + 1
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-gray-700 border hover:bg-gray-100'
                      }`}
                      onClick={() => cambiarPagina(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                {paginaActual < totalPaginas && (
                  <button
                    className="px-3 py-1 bg-white text-gray-700 rounded-md border hover:bg-gray-100"
                    onClick={() => cambiarPagina(paginaActual + 1)}
                  >
                    Siguiente
                  </button>
                )}
              </div>
            </>
          }
        />
      </Routes>
      
      {/* Menú desplegable para móviles */}
      {openMenuId && createPortal(
        <div
          ref={menuRef}
          style={{
            position: 'absolute',
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
            zIndex: 1000,
          }}
        >
          <div className="bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleViewDetails(enviosActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaSearch className="mr-2" /> Ver detalles
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleEditEnvio(enviosActuales.find(e => e.id === openMenuId));
                  handleMenuClose();
                }}
              >
                <FaEdit className="mr-2" /> Editar
              </button>
              <button
                className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleDelete(openMenuId);
                  handleMenuClose();
                }}
              >
                <FaTrash className="mr-2" /> Eliminar
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
}