import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PasswordReset() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/reset-password/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Password reset request failed');
      }

      setSuccess('Se han enviado las instrucciones a tu correo electrónico.');
    } catch (error) {
      console.error('Error during password reset request:', error);
      setError('No se pudo procesar la solicitud. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8 font-sans">
      <div className="flex-grow flex items-center justify-center w-full">
        <div className="w-full max-w-md bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
          <div className="mb-6 text-center">
            <img
              alt="Agromaderas logo"
              className="h-16 w-auto sm:h-20 md:h-24 mx-auto"
              src="/agromaderas_logo.png"
            />
            <h2 className="text-2xl font-bold mt-4 text-gray-900">Recuperá tu contraseña</h2>
            <p className="mt-2 text-sm text-gray-600">
              Ingresá el email asociado con tu cuenta. Te enviaremos instrucciones a esa dirección para recuperar tu contraseña.
            </p>
          </div>
          {error && (
            <div className="text-red-500 text-sm mb-4">
              {error}
            </div>
          )}
          {success && (
            <div className="text-green-500 text-sm mb-4">
              {success}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="text-left">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Correo electrónico
              </label>
              <input
                id="email"
                type="email"
                placeholder="Ingrese su correo electrónico"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                           focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500"
              />
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
              >
                Enviar instrucciones
              </button>
            </div>
          </form>
          <div className="mt-6 text-center">
            <a href="/login" className="text-sm text-gray-600 hover:text-gray-800 transition-colors duration-200">
              Volver al inicio de sesión
            </a>
          </div>
        </div>
      </div>
      <div className="w-full text-center py-4">
        <p className="text-xs text-gray-500">Powered by <b>ZALBEX Technologies</b></p>
      </div>
    </div>
  );
}