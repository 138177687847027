import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

interface EnvioDetailsProps {
  envio: {
    details: string;
    estimated_delivery_date: string;
    real_delivery_date: string;
    address: string;
    order: number;
  };
  onClose: () => void;
}

const EnvioDetails: React.FC<EnvioDetailsProps> = ({ envio, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(amount);
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 py-8 text-center">
        <div className={`inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-900">Envio orden #{envio.order}</h2>
            <div className="flex items-center">
              <button onClick={handleClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="mt-4 space-y-4">
            {/* Primera fila: Nro. de orden y Precio */}
            <div className="grid grid-cols-2 gap-4">
            
                <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Direccion de entrega</label>
              <p className="text-sm text-gray-700">{envio.address}</p>
                </div>
            </div>

            {/* Segunda fila: Fecha inicio y Fecha fin */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-500">Fecha estimada de entrega</label>
                <p className="mt-1 text-sm font-semibold">{formatDate(envio.estimated_delivery_date)}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Fecha real de entrega</label>
                <p className="mt-1 text-sm font-semibold">{formatDate(envio.real_delivery_date)}</p>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Detalle</label>
              <p className="text-sm text-gray-700">{envio.details}</p>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvioDetails;