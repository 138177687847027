import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UpdateStatus({ editingProduccion, onClose, onEstadoActualizado }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true); // Asegúrate de que el modal esté abierto por defecto
  const [formData, setFormData] = useState({
    production: '',  // Almacenará el client_id seleccionado
    status: '',
    comments: '',
  });

  // Efecto para actualizar el campo production cuando editingProduccion esté disponible
  useEffect(() => {
    if (editingProduccion) {
      setFormData((prevData) => ({
        ...prevData,
        production: editingProduccion.id, // Usar el ID de editingProduccion
      }));
    }
  }, [editingProduccion]);

  // Lista de estados hardcodeados
  const estados = [
    { value: 'pending', label: 'Pendiente' },
    { value: 'confirm', label: 'Confirmado' },
    { value: 'in_production', label: 'En produccion' },
    { value: 'ready_for_shipment', label: 'Listo para entrega' },
    { value: 'ended', label: 'Terminado' },
    { value: 'canceled', label: 'Cancelado' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ajustar la fecha al formato correcto si solo hay fecha y no hora
    const adjustedFormData = {
      ...formData
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.agromaderaslaboulaye.com.ar/api/status-change/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(adjustedFormData),
      });

      if (response.ok) {
        toast.success('Producción actualizada con éxito');
        await sleep(1500);
        onEstadoActualizado();
        handleClose();
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.description_es || 'Actualizacion de estado de produccion fallida';
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error('Error al intentar actualizar la producción');
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300); // Delay closing to allow animation to complete
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 ease-in-out`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={handleClose}></div>
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className={`inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'} duration-300 ease-in-out`}>
          <ToastContainer />
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Actualizar estado de producción</h2>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                  Estado
                </label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                  required
                >
                  <option value="">Selecciona un estado</option>
                  {estados.map((estado) => (
                    <option key={estado.value} value={estado.value}>
                      {estado.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="comments" className="block text-sm font-medium text-gray-700">
                  Comentarios
                </label>
                <input
                  type="text"
                  id="comments"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={handleClose}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200 ease-in-out"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
              >
                Actualizar Estado
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
